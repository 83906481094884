<template>
	<div>
		<TheHeader
			v-if="showHeaderAndFooter"
			:dark="pageColor === 'dark'"
		/>
		<slot></slot>
		<TheFooter v-if="showHeaderAndFooter"></TheFooter>
	</div>
</template>
<script setup>
import { useStore } from "vuex";

const store = useStore();

const page = computed(() => {
	return store.state.page;
});
const showHeaderAndFooter = computed(() => {
	const layoutsWithoutHeaderAndFooter = ["stripped", "streamingPlusPage", "tweBoxWebApp", "tweBoxInfo"];
	return !layoutsWithoutHeaderAndFooter.some((layout) => layout === layoutRef.value);
});

const pageColor = computed(() => {
	// TODO: Make into composable
	// TODO implement page color
	if (page.value?.metadata.dark || layoutRef.value === "tweDefault") {
		// TODO tweDefault is deprecated but need to wait for content to switch to normal page with dark mode selected
		return "dark";
	} else {
		return "white";
	}
});

const layoutRef = computed(() => {
	// TODO: Make into composable
	if (page.value?.template?.ref) {
		return page.value.template.ref.split("/").pop();
	}
	return "default";
});
</script>
