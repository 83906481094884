<template>
	<div
		class="top-menu-bar"
		:style="{ height: headerHeight + 'px' }"
		:class="[open && 'open', dark && 'dark', searchOpen && 'search-open']"
	>
		<div class="content-container content">
			<div class="left-items">
				<SegmentButtons
					v-if="segments.length > 0"
					:segments="segments"
					class="segment-buttons"
					:class="{ hidden: searchOpen }"
					:dark="dark"
				></SegmentButtons>

				<CustomerMenu
					v-if="$store.state.siteSegment === 'privat'"
					:menu-hidden="menuHidden"
					:dark="dark"
					:headerLogin="header.headerLogin"
					:class="{ 'login-mobil': true, hidden: searchOpen, 'padding-left-none padding-right-none': true }"
				/>
				<LinkWrap
					v-else
					@click="loginClicked"
					:to="loginLink"
					class="login-mobil"
					:class="{ hidden: searchOpen }"
				>
					<TnIcon
						name="person"
						size="l"
						:dark="dark"
					></TnIcon>
				</LinkWrap>

				<SearchWidget
					class="search-widget"
					:dark="dark"
					:open="searchOpen"
					:searchPreviewOpen="searchOpen"
					:segments="segments"
					@open="openSearch"
					@close="closeSearch"
					@close-search-preview="closeSearchPreview"
					@update-search-query="(query) => $emit('update-search-query', query)"
					@abort-search="$emit('abort-search')"
					v-if="$route.name !== 'sok'"
				></SearchWidget>
			</div>

			<div class="center-items">
				<LinkWrap
					:to="logoLink"
					non-editable
				>
					<img
						class="logo"
						src="/telenorlogo.svg"
						alt="Telenor logo"
					/>
				</LinkWrap>
			</div>

			<div class="right-items">
				<!-- Temporary item until login component is ready -->
				<CustomerMenu
					v-if="$store.state.siteSegment === 'privat'"
					class="hidden-mobile padding-left-m padding-right-m"
					:menu-hidden="menuHidden"
					:headerLogin="header.headerLogin"
				/>

				<LinkWrap
					v-else
					style="padding: 0; display: flex; align-items: center"
					class="hidden-mobile padding-left-m padding-right-m"
					@click="loginClicked"
					:to="loginLink"
					non-editable
				>
					<p>{{ loginText }}</p>
					<TnIcon
						name="person"
						size="l"
					/>
				</LinkWrap>

				<LazyShoppingCart
					:menu-hidden="menuHidden"
					:dark="dark"
				/>

				<div
					class="header-menu"
					@click="$emit('toggle')"
					@keydown.enter="$emit('toggle')"
					:aria-expanded="open"
					tabindex="0"
					role="button"
				>
					<MenuButton
						:open="open"
						:dark="dark"
					></MenuButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SupportsDarkMode from "../../../../../mixins/SupportsDarkMode";
import IsTestMixin from "~/mixins/IsTestMixin.js";
import { mapGetters } from "vuex";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "MenuBar",
	mixins: [SupportsDarkMode, IsTestMixin],

	props: ["open", "segments", "login-url", "header", "menu-hidden", "searchIsOpen"],

	data() {
		return {
			searchOpen: false,
		};
	},

	computed: {
		...mapGetters("ecommerce", ["segment"]),
		loginText() {
			if (this.$store.state.customer && this.$store.state.customer.firstName)
				return this.$store.state.customer.firstName;
			else return this.logoutLink?.text;
		},
		logoutLink() {
			return LinkViewModel(this.header?.headerLogin?.trigger?.loggedOut);
		},
		headerLoginShortcuts() {
			return this.header?.headerLogin?.menu?.shortcuts?.map((shortcut) => LinkViewModel(shortcut));
		},
		headerHeight() {
			return this.$store.getters.isMobile ? 60 : 80;
		},
		logoLink() {
			return "/" + this.$store.state.siteSegment + "/";
		},
		loginLink() {
			if (this.$store.state.siteSegment === "bedrift") return "https://www.telenor.no/bedrift/minbedrift/";
			else if (this.$store.state.customer) {
				return "/mitt-telenor/";
			}
			return this.loginUrl;
		},
	},

	methods: {
		async loginClicked(event) {
			if (this.$store.state.siteSegment === "bedrift") {
				event.preventDefault();
				this.$emit("loginClicked");
			}
		},
		openSearch() {
			this.searchOpen = true;
			this.$emit("show-search-preview");
		},
		closeSearch() {
			this.searchOpen = false;
			this.$emit("close-search-preview");
		},
		openSearchPreview() {
			this.searchOpen = true;
			this.$emit("open-search-preview");
		},
		closeSearchPreview() {
			this.searchOpen = false;
			this.$emit("close-search-preview");
		},
	},
});
</script>

<style scoped lang="scss">
.top-menu-bar {
	position: relative;
	z-index: 1;
	height: 80px;
	border-bottom: 1px solid transparent;
	transition: border-bottom-color 0.3s;
	user-select: none;
	background: #fff;

	&.dark {
		background: $color-twe-background;
	}

	&.open {
		border-bottom: 1px solid $color-neutrals-200-tint;

		@include breakpoint(mobile) {
			.segment-buttons {
				display: block;
			}

			.login-mobil {
				display: none;
			}

			.search-widget {
				display: none;
			}

			.logo {
				display: none;
			}
		}
	}

	&.dark.open {
		border-bottom-color: #15192d;
	}

	.content {
		margin: 0 auto;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;

		> div {
			margin-bottom: -3px;

			@include breakpoint(mobile) {
				margin-bottom: 0;
			}
		}
	}

	a {
		color: inherit;
	}

	.right-items,
	.left-items {
		width: 40%;
	}

	.right-items {
		display: flex;
		place-content: center flex-end;
		align-items: center;

		.header-menu {
			cursor: pointer;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			// &:focus {
			//   outline: -webkit-focus-ring-color auto 5px;
			// }
			&:focus-visible {
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}

	.center-items {
		img {
			height: 50px;

			@media (max-width: $size-screen-s) {
				height: 40px;
			}
		}
	}

	.left-items {
		display: flex;
		align-items: center;

		a,
		.login-mobil {
			height: 32px;
			padding: 0;
			text-decoration: none;

			&.main-section {
				padding: 15px 12px;
				text-align: center;

				@include font-text-m;

				+ .main-section {
					margin-left: 16px;
				}

				&.active {
					border-bottom: 1px solid #2dabee;
				}
			}
		}
	}

	.segment-buttons {
		margin-right: 30px;
		transition: all 0.3s ease;
		opacity: 1;
		width: 163px;
		white-space: nowrap;

		&.hidden {
			width: 0;
			opacity: 0;
			margin-right: 0;
			pointer-events: none;
		}
	}

	.login-mobil {
		display: none;

		&.hidden {
			width: 0;
			opacity: 0;
			padding: 0;
			margin-right: 0;
		}

		@include breakpoint(mobile) {
			display: block;
			padding: $spacing-m;
			margin-right: $spacing-m;
			margin-top: 2px;
		}
	}

	@include breakpoint(mobile) {
		height: 60px;

		&.open {
			border-bottom: 0;
		}

		.segment-buttons {
			display: none;
		}
	}

	@include breakpoint(mobile) {
		max-height: 60px;
	}

	@include breakpoint(mobile) {
		&.search-open {
			.left-items {
				width: 100%;
			}

			.center-items {
				display: none;
			}

			.right-items {
				display: none;
			}
		}
	}
}
</style>
