<template>
	<header
		id="TheHeader"
		class="menu-container"
		aria-label="Telenor logo og hovedmeny"
		:class="wrapperClasses"
	>
		<MenuBar
			:open="menuOpen"
			:searchIsOpen="showSearchPreview"
			:segments="segments"
			:dark="dark"
			:login-url="header.loginUrl"
			:header="header"
			:menu-hidden="menuHidden"
			@toggle="toggleMenu"
			@loginClicked="bedriftLoginMenuOpen = !bedriftLoginMenuOpen"
			@click="menuToggled"
			@close-search-preview="closeSearchPreview"
			@abort-search="setAbortStatus(true)"
			@update-search-query="(query) => (searchQuery = query)"
		/>
		<LazySearchPreview
			v-if="!$store.getters.isMobile && searchQuery"
			:query="searchQuery"
			:abort="abortSearch"
			:close="closePreview"
			:menuOpen="menuOpen"
			@reset-close-preview="resetClosePreview"
			@close-menu="(e) => toggleMenu()"
			@search-preview-open="setSearchPreviewStatus"
			@search-successfully-aborted="setAbortStatus(false)"
		/>
		<DropdownMenu
			:categories="header.categories"
			:shortcuts="header.shortcuts"
			:open="menuOpen"
			:searchIsOpen="showSearchPreview"
			:dark="dark"
		/>
		<DropdownMenu
			:categories="header.secondaryCategories"
			:buttons="header.secondaryShortcuts"
			:open="bedriftLoginMenuOpen"
			:dark="dark"
			v-if="header.secondaryCategories"
		/>
	</header>
</template>

<script>
import SupportsDarkMode from "../../../../mixins/SupportsDarkMode";
import GizmoHeaderViewModel from "./GizmoHeaderViewModel.js";
import GizmoComponentMixin from "../../../../mixins/GizmoComponentMixin.js";
import { mapState } from "vuex";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

function setHeaderLinks(header) {
	header.content.firstMainItem = header.content.firstMainItem && LinkViewModel(header.content.firstMainItem);
	header.content.secondMainItem = header.content.secondMainItem && LinkViewModel(header.content.secondMainItem);
	if (header.content.headerMenuItem) {
		header.content.headerMenuItem.map((item) => {
			item.menuLinks = item.menuLink.map(LinkViewModel);
			item.categoryHeading = LinkViewModel(item.menuHeading);
			return item;
		});
	}
	header.content.headerShortCutLinks = header.content.headerShortCutLinks?.map(LinkViewModel);
	if (header.content.headerSecondaryMenuItem) {
		header.content.headerSecondaryMenuItem.map((item) => {
			const menuHeading = {
				linkText: item.menuHeading,
			};
			item.categoryHeading = LinkViewModel(menuHeading);
			item.menuLinks = item.menuLink.map(LinkViewModel);
			return item;
		});
	}
	return header;
}
export default defineNuxtComponent({
	name: "TheHeader",
	mixins: [SupportsDarkMode, GizmoComponentMixin],
	watch: {
		"$route.path"() {
			this.closeMenu();
		},
	},
	data() {
		return {
			menuHidden: false,
			menuOpen: false,
			showSearchPreview: false,
			closePreview: false,
			searchQuery: "",
			abortSearch: false,
			bedriftLoginMenuOpen: false,
			lastScrollTop: 0,
			pageScrollTop: 0,
			scrollTop: 0,
		};
	},
	created() {
		if (import.meta.client) {
			window.addEventListener("scroll", this.handleScroll);
		}
	},
	unmounted() {
		if (import.meta.client) {
			window.addEventListener("scroll", this.handleScroll);
			document.querySelector("html").classList.remove("lock-mobile-scroll");
			this.menuOpen = false;
		}
	},
	computed: {
		...mapState(["siteSegment", "isMobile"]),
		header() {
			let fromState = this.$store.state.globals[this.siteSegment].header;

			if (!fromState) {
				// New header isn't loaded yet, find the first available header

				for (const key in this.$store.state.globals) {
					if (this.$store.state.globals[key].header) {
						fromState = this.$store.state.globals[key].header;
						break;
					}
				}
			}

			if (!fromState) return {};

			fromState = JSON.parse(JSON.stringify(fromState));
			fromState = setHeaderLinks(fromState);
			fromState = GizmoHeaderViewModel(fromState);
			return fromState;
		},
		wrapperClasses() {
			return [
				{ dark: this.dark },
				{ shade: this.menuOpen },
				{ "hide-menu": this.menuHidden },
				{ scrolled: this.scrollTop !== 0 },
			];
		},
		segments() {
			return this.header?.segments.map((segment) => {
				return {
					...segment,
					active: segment.to.indexOf(this.siteSegment) > -1,
				};
			});
		},
		height() {
			return this.isMobile ? 60 : 80;
		},
	},
	methods: {
		toggleMenu() {
			if (!this.menuOpen && this.showSearchPreview) {
				this.closeSearchPreview();
			}
			this.menuOpen = !this.menuOpen;
		},
		closeSearchPreview() {
			this.closePreview = true;
		},
		setAbortStatus(value) {
			this.abortSearch = value;
		},
		setSearchPreviewStatus(e) {
			this.showSearchPreview = e;
		},
		resetClosePreview() {
			this.closePreview = false;
		},
		handleScroll() {
			this.scrollTop = window.scrollY;
			const nowScrollTop = this.scrollTop >= 0 ? this.scrollTop : 0;
			if (!this.menuOpen) {
				const scrollDiff = Math.abs(this.lastScrollTop - nowScrollTop);
				if (scrollDiff > 0) {
					const delta = this.height;
					if (nowScrollTop > this.lastScrollTop && scrollDiff > delta) {
						this.menuHidden = true;
						this.setLastScrollTop(nowScrollTop);
					}
					if (nowScrollTop < this.lastScrollTop) {
						this.menuHidden = false;
						this.setLastScrollTop(nowScrollTop);
					}
				}
			} else {
				this.setLastScrollTop(nowScrollTop);
			}
		},
		setLastScrollTop(top) {
			this.lastScrollTop = top;
		},
		closeMenu() {
			if (import.meta.client) {
				document.querySelector("html").classList.remove("lock-mobile-scroll");
				this.menuOpen = false;
			}
		},
		menuToggled() {
			if (import.meta.client) {
				if (this.menuOpen) document.querySelector("html").classList.add("lock-mobile-scroll");
				else document.querySelector("html").classList.remove("lock-mobile-scroll");
			}
		},
	},
});
</script>

<style scoped lang="scss">
.translated-ltr {
	header {
		top: 40px;
	}
}

header {
	z-index: 1003;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transform: translateY(0);
	transition: all 200ms ease-in-out;
	color: #333;

	@include font-text-m;

	:deep(.modal-wrapper) {
		transform: translateY(0);
	}

	&.shade {
		box-shadow:
			0 0 10px rgb(0 0 0 / 30%),
			inset 0 0 0 1px hsl(0deg 0% 76.9% / 5%);
	}

	&.attached {
		position: relative;
	}

	&.hide-menu {
		transform: translateY(-100%);
		box-shadow:
			0 0 10px rgb(0 0 0 / 2%),
			inset 0 0 0 1px hsl(0deg 0% 76.9% / 5%);

		:deep(.modal-wrapper) {
			transform: translateY(80px);
			transition: transform 0.2s ease-in-out;

			@include breakpoint(mobile) {
				transform: translateY(60px);
			}
		}
	}

	&.dark {
		color: #fff;

		.search-preview {
			color: $color-neutrals-black;
		}

		.dropdown-menu-containerTIYP {
			background: $color-neutrals-black;

			a {
				color: #fff;
			}
		}

		.menu-container {
			background: $color-neutrals-black;

			.tab-bar {
				background: $color-neutrals-black;

				a {
					color: #fff;
				}
			}

			.dropdown-link-container {
				background: $color-neutrals-black;

				&.active {
					background: #fff;
				}

				ul.container {
					.dropdown-link {
						a.link {
							color: #fff;
						}

						img.white.arrow-up {
							opacity: 0;
						}

						img.white.arrow-down {
							opacity: 0.6;
						}

						&.active {
							img.white.arrow-up {
								opacity: 0.6;
							}

							img.white.arrow-down {
								opacity: 0;
							}
						}
					}

					.extra-column {
						.link {
							color: #afafb0;
						}
					}
				}
			}
		}
	}

	@media screen and (width >= 769px) {
		max-height: 100vh;
	}
}
</style>
