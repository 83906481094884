<template>
	<div
		class="search-widget"
		:class="{ dark }"
	>
		<Transition mode="out-in">
			<form
				:action="searchAction"
				method="GET"
				ref="searchForm"
				class="search-form"
				@submit="handleSubmit"
			>
				<input
					type="hidden"
					name="collection"
					:value="segment"
				/>
				<input
					type="hidden"
					name="cat"
					:value="categoryId"
				/>
				<label
					for="search"
					@click="$emit('open')"
				>
					<TnIcon
						name="search"
						class="search-icon"
						:color="dark ? 'white' : ''"
						size="m"
					/>
					<input
						type="text"
						id="search"
						name="q"
						ref="searchInput"
						:placeholder="placeholderQuery || 'Søk'"
						v-model="query"
						autocomplete="off"
						:class="{ 'search-input': true, open: open }"
						@focus="$emit('open')"
						@blurp="close"
						@keydown.esc="close"
						@keyup.esc="$event.target.blur()"
						@keydown.enter="handleSubmit"
					/>
				</label>
				<Transition name="fade">
					<TnIcon
						name="close"
						class="close-icon"
						size="s"
						v-if="open"
						@click="close"
						:color="dark ? 'white' : ''"
					/>
				</Transition>
				<input
					type="hidden"
					name="collection"
					:value="segment"
				/>
			</form>
		</Transition>
	</div>
</template>

<script>
import SupportsDarkMode from "../../../../../mixins/SupportsDarkMode";

export default defineNuxtComponent({
	name: "SearchWidget",
	mixins: [SupportsDarkMode],
	props: ["open", "segments", "searchPreviewOpen"],

	computed: {
		searchAction() {
			return "/sok/";
		},
		segment() {
			return this.$store.state.siteSegment;
		},
		isEcommerceContext() {
			return ["/mobiltelefoner", "/tilbehor", "/smartklokker", "/lyd", "/nettbrett", "/ruter"].some(
				(path) => this.$route.path.indexOf(path) === 0,
			);
		},
		isCustomerServiceContext() {
			return ["/kundeservice"].some((path) => this.$route.path.indexOf(path) === 0);
		},
		categoryId() {
			if (this.isEcommerceContext) return "1";
			else if (this.isCustomerServiceContext) return "2";
			return "0";
		},
	},

	data() {
		return {
			query: "",
			placeholderQuery: "",
		};
	},

	methods: {
		close(event) {
			if (event.relatedTarget?.nodeName === "A") {
				return;
			}
			this.query = "";
			if (this.searchPreviewOpen) {
				this.$emit("close-search-preview");
			}
			this.$emit("abort-search");
			this.$emit("close");
		},
		handleSubmit(event) {
			event.preventDefault();
			event.stopPropagation();
			this.$emit("abort-search");
			this.$emit("close-search-preview");

			this.$router.push({
				name: "sok",
				query: {
					q: this.query,
					collection: this.segment,
					cat: this.categoryId,
				},
			});
		},
	},

	watch: {
		query(newQuery) {
			this.$emit("update-search-query", newQuery);
		},
		$route(to) {
			if (to.query && to.query.q) {
				this.placeholderQuery = to.query.q;
				this.query = "";
				this.$refs.searchInput.blur();
			} else {
				this.placeholderQuery = "";
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.dark {
	.search-icon {
		:deep(path) {
			fill: #fff;
		}
	}

	.search-input {
		color: #fff;

		&::input-placeholder {
			/* Edge */
			color: #fff;
		}

		&:input-placeholder {
			/* Internet Explorer 10-11 */
			color: #fff;
		}

		&::placeholder {
			color: #fff;
		}

		@include breakpoint(mobile) {
			&::input-placeholder {
				/* Edge */
				color: transparent;
			}

			&:input-placeholder {
				/* Internet Explorer 10-11 */
				color: transparent;
			}

			&::placeholder {
				color: transparent;
			}

			&:focus {
				&::input-placeholder {
					/* Edge */
					color: #fff;
				}

				&:input-placeholder {
					/* Internet Explorer 10-11 */
					color: #fff;
				}

				&::placeholder {
					color: #fff;
				}
			}
		}
	}
}

.search-widget {
	flex-grow: 1;

	@include breakpoint(mobile) {
		margin-top: 0;
	}
}

.search-form,
.search-form label {
	flex-grow: 1;
}

.search-button,
.search-form,
.search-form > label {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.search-icon {
	margin-right: 12px;
	cursor: pointer;
}

.close-icon {
	opacity: 0.5;
	cursor: pointer;
}

.search-input {
	height: 100%;
	border: none;
	font-family: inherit;
	font-size: 18px;
	flex-grow: 1;
	background: transparent;
	caret-color: $color-primary-telenor;
	color: #1a1a1a;

	&:focus {
		outline: none;
	}

	&::input-placeholder {
		/* Edge */
		color: #1a1a1a;
	}

	&:input-placeholder {
		/* Internet Explorer 10-11 */
		color: #1a1a1a;
	}

	&::placeholder {
		color: #1a1a1a;
	}

	@include breakpoint(mobile) {
		display: none;

		&::input-placeholder {
			/* Edge */
			color: transparent;
		}

		&:input-placeholder {
			/* Internet Explorer 10-11 */
			color: transparent;
		}

		&::placeholder {
			color: transparent;
		}

		&:focus {
			&::input-placeholder {
				/* Edge */
				color: #1a1a1a;
			}

			&:input-placeholder {
				/* Internet Explorer 10-11 */
				color: #1a1a1a;
			}

			&::placeholder {
				color: #1a1a1a;
			}
		}

		&.open {
			display: block;
		}
	}
}

/* Enter and leave animations can use different */

/* durations and timing functions.              */
.fade-enter-active {
	transition: opacity 0.2s ease;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter-from, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
