<template>
	<Transition name="dd">
		<div
			class="dropdown-menu-wrapper"
			:class="menuClass"
			v-if="open && !searchIsOpen"
			:aria-expanded="open"
		>
			<nav class="dropdown-menu content-container">
				<ul class="dropdown-content category">
					<LazyButtons
						v-if="buttons"
						:buttons="buttons"
					></LazyButtons>
					<MenuCategoryList
						v-for="(menuItem, index) in categories"
						:category-heading="menuItem.categoryHeading"
						:menu-links="menuItem.menuLinks"
						:key="`category-${index}`"
						:dark="dark"
						:index="index"
					/>
					<LazyShortcutLinks
						:menu-open="open"
						:shortcut-links="shortcuts"
					/>
				</ul>
			</nav>
		</div>
	</Transition>
</template>

<script>
import SupportsDarkMode from "../../../../../mixins/SupportsDarkMode";

export default defineNuxtComponent({
	name: "dropdown-menu",
	mixins: [SupportsDarkMode],

	props: {
		categories: Array,
		shortcuts: Array,
		mobileLink: Object,
		open: Boolean,
		searchIsOpen: Boolean,
		buttons: Array,
	},

	computed: {
		menuClass() {
			return [this.dark && "dark"];
		},
	},
});
</script>

<style lang="scss" scoped>
.dropdown-menu-wrapper {
	background: $color-neutrals-white;

	:deep(.dropdown-category:not(.dark)) {
		.dropdown-link-container .link {
			color: $color-neutrals-700-shade;
		}

		.shortcut-link {
			font-weight: bold;
			color: $color-neutrals-black;

			@include breakpoint(mobile) {
				&:last-child {
					height: 110px; // Compensate for Safari navigation on iOS
				}
			}
		}
	}

	&.dark {
		background: $color-twe-background;
		color: $color-neutrals-white;

		:deep(.dropdown-category) {
			.shortcut-link {
				color: $color-neutrals-white;
			}
		}

		.icon-container {
			.small-icon-text {
				color: $color-neutrals-white;
			}
		}
	}

	@include breakpoint(mobile) {
		width: 100%;
		position: fixed;
		top: 60px;
		z-index: 1337;
		height: calc(100vh - 60px);
		overflow-y: scroll;
		padding-bottom: $spacing-3xl; // Compensate for Safari navigation on iOS
	}
}

.dropdown-menu {
	overflow: hidden;

	ul {
		padding: 30px 0;
		list-style-type: none;
		min-width: 150px;

		&.dropdown-content {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}

		@include breakpoint(mobile) {
			width: 100%;
			flex-direction: column;
			padding: 0;
			text-indent: 10px;
		}
	}

	.icon-container {
		padding: 3px 0 25px 30px;
		margin: 10px 0 0;
		display: block;
		float: left;

		.icon-img,
		.small-icon-text {
			display: block;
			float: left;
		}

		.icon-img {
			margin-top: 5px;

			img {
				max-width: 37px;
				max-height: 37px;
				width: auto;
			}
		}

		.small-icon-text {
			width: 80%;
			text-decoration: none;
			color: $color-text;
			margin: 5px 10px;

			@include font-text-s;
		}
	}

	@include breakpoint(mobile) {
		background: inherit;
	}
}

.dd-enter-active,
.dd-leave-active {
	transition: transform 0.2s;
	transform: translateY(0);

	@include breakpoint(mobile) {
		transform: translateX(0);
	}
}

.dd-enter-from,
.dd-leave-to {
	transform: translateY(-100%);

	@include breakpoint(mobile) {
		transform: translateX(100%);
	}
}
</style>
