export default (component) => {
	let content = component.content;

	return {
		...content,
		segments: [content.firstMainItem, content.secondMainItem].filter((item) => item),
		categories: content.headerMenuItem,
		shortcuts: content.headerShortCutLinks,
		secondaryCategories: content.headerSecondaryMenuItem,
		secondaryShortcuts: content.headerSecondaryShortCutButtons,
		loginUrl: content.headerLoginUrl,
	};
};
